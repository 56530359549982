<template>
  <div class="takeClothesIndexView content-index">
    <div class="topView">
      <div class="tView">
          <el-page-header
            style="width: 100%;padding:10px"
            @back="$router.go(-1)"
            content="取衣"
          ></el-page-header>
      </div>
    </div>
    <div class="centent">
      <div class="tipView">请选择您要操作哪一项？</div>
      <div class="boxView">
        <!-- <div @click.stop="goPath('takeClothes')">我要取衣</div> -->
        <div @click.stop="goPath('clothes')">我要取衣</div>
        <div @click="goPath('alreadyTake')">
          <p>查看</p>
          <p>已取衣物</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {};
  },
  methods: {
    // 路由跳转
    goPath(path) {
      this.$router.push({ name: path });
    },
  },
};
</script>

<style scoped lang="scss">
.takeClothesIndexView {
  position: relative;
  overflow: auto;
  font-family: PingFangSC-Regular;

  > .topView {
    padding: 17.5px 20px;

    > .tView {
      color: #333;
      text-align: left;
      font-size: 24px;
    }
  }
  .centent {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    > .tipView {
      font-size: 50px;
      color: #080808;
      font-weight: 540;
    }

    > .boxView {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      color: #ffffff;
      font-size: 24px;
      font-weight: 600;

      > div {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      > div:hover {
        cursor: pointer;
      }

      > div:nth-child(1) {
        background-color: #0062da;
        margin-right: 50px;
      }

      > div:nth-child(2) {
        background-color: #ba0f11;
        margin-left: 50px;
        flex-direction: column;

        > p {
          margin: 0;
        }
      }
    }
  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>
